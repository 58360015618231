const contentful = require('contentful');

class ContentfulClient {

  constructor() {
    this.personalAccessToken = process.env.CF_PERSONAL_ACCESS_TOKEN;
    this.deliveryAccessToken = process.env.CF_DELIVERY_ACCESS_TOKEN;
    this.spaceId = process.env.CF_SPACE_ID;
    this.environmentId = process.env.CF_ENVIRONMENT;

    if(this.deliveryAccessToken && this.deliveryAccessToken !== '') {
      this.contentfulClient = contentful.createClient({
        space: this.spaceId,
        accessToken: this.deliveryAccessToken,
        environment: this.environmentId
      });
    }

  }

  static create() {
    return new ContentfulClient();
  }
  getPersonalAccessToken() {
    return this.personalAccessToken;
  }
  getDeliveryAccessToken() {
    return this.deliveryAccessToken;
  }
  getSpaceId() {
    return this.spaceId;
  }
  getEnvironmentId() {
    return this.environmentId;
  }

  async getClient() {
    return this.contentfulClient;
  }
}

export default new ContentfulClient();